import * as yup from 'yup'
import { PartnerPrograms } from '@/constants/partner-programs'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signupValidationSchema = (
  currenProgram: string,
  hasReferralCode = false,
): any => {
  const baseSchema = signupValidationSchemaBase()
  if (currenProgram == PartnerPrograms.AKKO)
    return signupValidationSchemaByAkkoByReferralCode(
      baseSchema,
      hasReferralCode,
    )
  return baseSchema
}

const signupValidationSchemaBase = (): any => {
  return yup.object().shape({
    business_name: yup.string().max(60).required('Required'),
    website: yup.string().url().max(60).nullable(),
    contact_first_name: yup.string().max(60).required('Required'),
    contact_last_name: yup.string().max(60).required('Required'),
    contact_phone: yup
      .string()
      .min(10, 'Contact Phone must be at least 10 characters')
      .max(11, 'Contact Phone must be at most 11 characters')
      .required('Required'),
    contact_email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
  })
}

const signupValidationSchemaByAkkoByReferralCode = (
  baseSchema: any,
  hasReferralCode: boolean,
): any => {
  baseSchema = signupValidationSchemaByAkko(baseSchema)
  if (hasReferralCode) return signupValidationSchemaReferralCode(baseSchema)
  return baseSchema
}

const signupValidationSchemaByAkko = (baseSchema: any): any => {
  return baseSchema.shape({
    about_akko: yup.string().required('Required'),
    about_akko_distributor: yup.string().when('about_akko', {
      is: '3',
      then: yup.string().required('Required'),
    }),
    about_akko_other: yup.string().when('about_akko', {
      is: '11',
      then: yup.string().required('Required'),
    }),
    country: yup.string().required('Required'),
    partner_type: yup.string().required('Required'),
  })
}

const signupValidationSchemaReferralCode = (baseSchema: any): any => {
  return baseSchema.shape({
    referral_code: yup.string().max(250).required('Required'),
  })
}

export const signupAndPartnerClaimValidationSchema = (): any => {
  return yup.object().shape({
    partner_id: yup.number().required().positive().integer(),
    website: yup.string().url().max(60),
    contact_first_name: yup.string().max(60).required('Required'),
    contact_last_name: yup.string().max(60).required('Required'),
    contact_phone: yup
      .string()
      .min(10, 'Contact Phone must be at least 10 characters')
      .max(11, 'Contact Phone must be at most 11 characters')
      .required('Required'),
    contact_email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loginValidationSchema = (): any => {
  return yup.object().shape({
    phone: yup
      .string()
      .min(10, 'Phone number must be at least 10 characters')
      .max(11, 'Phone number must be at most 11 characters')
      .required('Required'),
    password: yup.string().max(60).required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resetPasswordValidationSchema = (): any => {
  return yup.object().shape({
    phone: yup.string().min(10).max(11).required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addPropertiesValidationSchema = (): any => {
  return yup.object().shape({
    stores: yup.array().of(
      yup.object().shape({
        name: yup.string().max(60).required('Required'),
        website: yup
          .string()
          .url('website must be a valid URL')
          .max(60)
          .nullable(),
        place: yup.string().max(255).required('Required'),
        phone: yup
          .string()
          .min(10, 'Contact Phone must be at least 10 characters')
          .max(11, 'Contact Phone must be at most 11 characters')
          .required('Required'),
        email: yup
          .string()
          .max(60)
          .email('Enter a valid email address.')
          .required('Required'),
        repairs: yup.boolean().required('Required'),
        repairs_apply: yup.array().of(yup.number()),
      }),
    ),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addPaypalEmailValidationSchema = (): any => {
  return yup.object().shape({
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Email is required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partnerPropertyPhysicalStoreFormValidationSchema = (): any => {
  return yup.object().shape({
    name: yup.string().max(60).required('Required'),
    website: yup.string().url().max(60).nullable(),
    place: yup.string().max(255).required('Required'),
    phone: yup.string().min(10).max(11).required('Required'),
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
    provides_repairs: yup.boolean().required('Required'),
    repair_devices: yup.array().of(yup.number()),
    provides_sells: yup.boolean().required('Required'),
    provides_sell_cellular_services: yup.boolean().required('Required'),

    // Helper properties, since data hosting is hold by the Form component
    valid_place: yup.boolean().nullable(),
    place_details: yup.object().nullable(),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const electronicPartnerPropertyFormValidationSchema = (): any => {
  return yup.object().shape({
    name: yup.string().max(255).required('Required'),
    website: yup.string().url().max(60).required('Required'),
    phone: yup.string().min(10).max(11).required('Required'),
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partnerPropertyMobileFormValidationSchema = (): any => {
  return yup.object().shape({
    name: yup.string().max(60).required('Required'),
    website: yup.string().url().max(60).nullable(),
    place: yup
      .string()
      .max(255)
      .when('byAddressAndRadius', {
        is: true,
        then: (schema) => schema.required('Required'),
        otherwise: (schema) => schema.nullable(),
      }),
    phone: yup.string().min(10).max(11).required('Required'),
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
    provides_repairs: yup.boolean().required('Required'),
    repair_devices: yup.array().of(yup.number()),
    byZipCodes: yup.boolean().nullable(),
    byAddressAndRadius: yup.boolean().nullable(),
    zipCodesAndFeeds: yup
      .array()
      .of(
        yup.object().shape({
          zipCode: yup.string().required('Required'),
          travelFee: yup.number().min(0).required('Required'),
        }),
      )
      .nullable(),
    radiusAndFeeds: yup
      .array()
      .of(
        yup.object().shape({
          radiusStart: yup.number().min(0).required('Required'),
          radiusEnd: yup.number().min(1).required('Required'),
          travelFee: yup.number().min(0).required(),
        }),
      )
      .nullable(),
    operationCentralAddress: yup.string().max(255).nullable(),
    addressDetails: yup.object().nullable(),
    provides_sells: yup.boolean().required('Required'),
    provides_sell_cellular_services: yup.boolean().required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePasswordFormValidationSchema = (): any => {
  return yup.object().shape({
    password: yup.string().max(60).required('Required'),
    repeat_password: yup
      .string()
      .max(60)
      .required('Required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partnerPropertyCreateUserFormValidationSchema = (): any => {
  return yup.object().shape({
    first_name: yup.string().max(60).required('Required'),
    last_name: yup.string().max(60).required('Required'),
    phone: yup.string().min(10).max(11).required('Required'),
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
    position_title: yup.string().max(60).required('Required'),
    id_number: yup.string().max(60).nullable(),
    partner_property_id: yup.string().max(60).required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserProfileFormValidationSchema = (): any => {
  return yup.object().shape({
    first_name: yup.string().max(60).required('Required'),
    last_name: yup.string().max(60).required('Required'),
    email: yup
      .string()
      .max(60)
      .email('Enter a valid email address.')
      .required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const claimSearchFormValidationSchema = (): any => {
  return yup.object().shape({
    claim_id: yup.string().min(1).max(11).required('Required'),
    phone: yup.string().min(10).max(11).required('Required'),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const endUserSearchFormValidationSchema = (): any => {
  return yup.object().shape({
    phone: yup.string().max(11),
    email: yup.string().max(60).email('Enter a valid email address'),
    serial_number: yup.string().max(60),
  })
}
